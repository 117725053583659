<template>
  <div>
    <base-template :isEmpty="cuponList.length == 0" :emptyImage="require('@/assets/images/stayPay/no-list-icon.png')">
      <template v-slot:content-top>
        <span class="clear-btn" @click="tipsShow = true" />
      </template>
      <div class="cupon-list">
        <cuponItem v-for="(item, index) in cuponList" :key="index" :money="item.TUZLhbtv" :time="item.IjjAhMsb"
          :title="item.title" :type="item.Umusango_Ibumba">
        </cuponItem>
      </div>
    </base-template>
    <van-popup v-model="tipsShow" round>
      <div class="popup">
        <div class="popup-box">
          <h3 class="tips-title">{{ $t('cupones.shiyongguize') }}</h3>
          <div class="tips-content">
            <p v-for="(item, i) in cuponTips" :key="i">{{ item }}</p>
          </div>
        </div>
        <img class="popup-close" @click="tipsShow = false" src="@/assets/images/coupon/popup-close.png" alt="">
      </div>

    </van-popup>
  </div>
</template>

<script>
import baseTemplate from '@/components/publicTemplate/components/baseTemplate.vue'
import cuponItem from './components/cuponItem.vue'
import { ticketList } from '@/api/axios/index'
export default {
  components: { baseTemplate, cuponItem },
  data() {
    return {
      tipsShow: false,
      cuponList: [],
      cuponTips: []
    }
  },
  created() {
    this.getInitCupon()
  },

  methods: {
    async getInitCupon() {
      console.log('aaaa');
      let { data } = await ticketList({ Umusango_Ibumba: 1 })
      console.log(data);
      this.cuponList = data.KfHoYvar.voucher_ticket.concat(data.KfHoYvar.withdraw_ticket)
      this.cuponTips = data.yimbo

    }




  }
}
</script>
<style lang="scss" scoped>
.clear-btn {
  width: 72px;
  height: 72px;
  position: absolute;
  right: 40px;
  top: 90px;
  background: url('~@/assets/images/coupon/tisp-icon.png') no-repeat center center;
  background-size: 100%;
}

.cupon-list {
  padding-top: 40px;
}

/deep/ .van-popup {
  overflow: inherit;
}

.popup {
  position: relative;

  .popup-close {
    position: absolute;
    width: 80px;
    height: 80px;
    bottom: -88px;
    left: 50%;
    margin-left: -40px;
  }

  .popup-box {
    padding: 40px;
    width: 670px;
    background: #FFFFFF;
    border-radius: 24px;

    .tips-title {
      font-size: 48px;
      font-family: Roboto-Bold, Roboto;
      font-weight: bold;
      color: #000000;
      line-height: 72px;
      text-align: center;
    }

    .tips-content {
      width: 606px;
      max-height: 746px;
      overflow-y: scroll;
      margin: 0 auto;
      padding: 32px;
      background: #F7F7F7;
      border-radius: 24px;
      line-height: 40px;
    }

  }
}

/deep/ .el-empty__image {
  img {
    width: 274px;
    height: 274px;
  }
}</style>
